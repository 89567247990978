<script setup>
import Right from 'assets/icons/right.svg';
import { useSideBar } from '~/stores/sideBar';

// Global store
const sideBarStore = useSideBar();
const { toggleSideBar } = sideBarStore;

const props = defineProps({
    config: {
        type: Object,
        default: () => {
        }
    }
});

const protectedItems = computed(() => !props.config?.restricted || props.config?.loginStatus);
</script>

<template>
    <li v-if="config.url && protectedItems">
        <NuxtLink class="account-overview-item" :to="config.url" @click="toggleSideBar({open:false})">
            <div class="account-overview-text">
                <span class="account-overview-icon">
                    <slot />
                </span>
                {{ config.text }}
            </div>
            <Right class="account-overview-action-icon" />
        </NuxtLink>
    </li>
    <li v-if="! config.url && protectedItems" class="account-overview-item" @click="config.fn">
        <div class="account-overview-text">
            <span class="account-overview-icon">
                <slot />
            </span>
            {{ config.text }}
        </div>
        <Right class="account-overview-action-icon" />
    </li>
</template>

<style lang="scss">
.account-overview-item {
    padding: 16px 24px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $account-overview-item-bg;
    color: $account-overview-item;
    border-radius: 20px;
    font-size: px2rem(14);
    line-height: px2rem(24);
    cursor: pointer;
    text-decoration: none;
    transition: box-shadow 400ms ease;

    &:hover {
        box-shadow: 4px 4px 5px $account-overview-item-shadow1, 2.5px 2.5px 0 $account-overview-item-shadow2;
    }
}

.account-overview-text {
    display: flex;
    align-items: center;
}

.account-overview-icon {
    border-radius: 20px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $account-overview-icon-bg;
    margin-right: 24px;

    svg {
        fill: $account-overview-icon-fill;
    }
}

.account-overview-action-icon {
    height: 14px;
    width: 8px;
}
</style>
