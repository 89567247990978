<script setup>
import { storeToRefs } from 'pinia';
import Settings from 'assets/icons/settings.svg';
import Preferences from 'assets/icons/preferences.svg';
import Faq from 'assets/icons/faq.svg';
import Agb from 'assets/icons/agb.svg';
import Legal from 'assets/icons/legal.svg';
import Imprint from 'assets/icons/imprint.svg';
import DataProtection from 'assets/icons/data-protection.svg';
import Cookie from 'assets/icons/cookie.svg';
import { requestAzure, signOut } from '~/composables/useMsal';
import { useCenterStore } from '~/stores/centerDetails';
import { useMessageStore } from '~/stores/messages';
import { useAuthStore } from '~/stores/auth';
import { useSideBar } from '~/stores/sideBar';

// Global store
const sideBarStore = useSideBar();
const { toggleSideBarSubNav } = sideBarStore;

// Auth store
const authStore = useAuthStore();
const { currentUser, loggedIn } = storeToRefs(authStore);

// Fetch messages
const messagesStore = useMessageStore();
const { messages: staticKeys } = storeToRefs(messagesStore);

// Button logout config
/*
const buttonLogoutConfig = {
    buttonType: 'secondary',
    type: 'div',
    active: true,
    large: true,
    fn: () => showNotificationLayer(true)
};
*/

const buttonLogoutConfig = {
    buttonType: 'secondary',
    type: 'div',
    active: true,
    large: true,
    fn: async () => await signOut()
};

// Button login config
const buttonLoginConfig = {
    buttonType: 'primary',
    type: 'div',
    large: true,
    active: true,
    fn: async () => await requestAzure('login')
};

// Show notification layer
// const logoutNotificationLayerIsVisible = ref(false);
/* const showNotificationLayer = (payload) => {
    logoutNotificationLayerIsVisible.value = payload;
} */

// Get center route
const { centerRoute } = storeToRefs(useCenterStore());

// Define items
const accountOverviewItems = [
    {
        text: staticKeys.value?.menu_personaldata,
        type: 'settings',
        fn: () => toggleSideBarSubNav({ open: true, content: 'personal' }),
        loginStatus: loggedIn.value,
        restricted: true
    },
    {
        text: staticKeys.value?.menu_preferences,
        type: 'preferences',
        fn: () => toggleSideBarSubNav({ open: true, content: 'preferences' }),
        loginStatus: loggedIn.value,
        restricted: true
    },
    {
        text: staticKeys.value?.menu_imprint,
        type: 'imprint',
        url: centerRoute.value ? `/${centerRoute.value}/impressum` : '/impressum',
        restricted: false
    },
    {
        text: staticKeys.value?.menu_dataprotection,
        type: 'dataprotection',
        url: centerRoute.value ? `/${centerRoute.value}/datenschutz` : '/datenschutz',
        restricted: false
    },
    {
        text: staticKeys.value?.menu_agb,
        type: 'agb',
        url: centerRoute.value ? `/${centerRoute.value}/agb` : '/agb',
        restricted: false
    },
    {
        text: staticKeys.value?.menu_faq,
        type: 'faq',
        url: centerRoute.value ? `/${centerRoute.value}/faq` : '/faq',
        restricted: false
    },
    {
        text: staticKeys.value?.menu_cookies,
        type: 'cmp',
        fn: () => showCMP(),
        restricted: false
    },
    {
        text: staticKeys.value?.menu_legal,
        type: 'legal',
        url: centerRoute.value ? `/${centerRoute.value}/rechtliches` : '/rechtliches',
        restricted: false
    }
]

const getSalutation = computed(() => currentUser.value?.lastName ? `${currentUser.value?.firstName} _${currentUser.value?.lastName}_` : `${currentUser.value?.firstName}`);
</script>

<template>
    <SideBarWrapper>
        <SideBarHeader>
            <template v-if="loggedIn">
                <SideBarMainHeadline v-if="currentUser?.firstName" :text="getSalutation" />
                <SideBarMainHeadline v-else :text="staticKeys?.account_headline_web_markdown" />
            </template>
            <template v-else>
                <SideBarMainHeadline :text="staticKeys?.menu_greetings_headline" />
                <p class="account-overview-intro">{{ staticKeys?.menu_greetings_subheadline }}</p>

                <div class="login-button-bar">
                    <UiButton :config="buttonLoginConfig">
                        {{ staticKeys?.account_login }}
                    </UiButton>
                </div>
            </template>
        </SideBarHeader>

        <ul class="account-overview-navigation">
            <UserAccountOverviewItem
                v-for="(item, index) in accountOverviewItems"
                :key="index"
                :config="item"
            >
                <Settings v-if="item.type === 'settings'" />
                <Preferences v-if="item.type === 'preferences'" />
                <DataProtection v-if="item.type === 'dataprotection'" />
                <Faq v-if="item.type === 'faq'" />
                <Agb v-if="item.type === 'agb'" />
                <Legal v-if="item.type === 'legal'" />
                <Imprint v-if="item.type === 'imprint'" />
                <Cookie v-if="item.type === 'cmp'" />
            </UserAccountOverviewItem>
            <!--
            <li>{{ staticKeys?.menu_parkingbills }}</li>
             <li>{{ staticKeys?.menu_valuecard }}</li>
            <li>{{ staticKeys?.menu_pushmessages }}</li>
            -->
        </ul>

        <UiButton v-if="loggedIn" :config="buttonLogoutConfig">
            {{ staticKeys?.account_logout }}
        </UiButton>

        <!-- Commented this out because its not part ticket or mvp-->
        <!--  <UiButton v-if="loggedIn" :config="buttonLogoutConfig">
           {{ staticKeys?.account_logout }}
       </UiButton>

      <Transition name="fade">
                   <LogoutNotificationLayer
                       v-if="logoutNotificationLayerIsVisible"
                       @update:show-notification-layer="showNotificationLayer($event)"
                   />
               </Transition>-->

        <div
            v-if="loggedIn" class="delete-account-link"
            @click="toggleSideBarSubNav({open: true, content: 'deleteAccount'})"
        >
            {{ staticKeys?.menu_deleteaccount }}
        </div>

        <DevOnly>
            <h1>currentUser</h1>
            <pre>{{ currentUser }}</pre>
        </DevOnly>
    </SideBarWrapper>
</template>

<style lang="scss">
.delete-account-link {
    @include textlink($margin: 30px 0 0);

    display: flex;
    justify-content: center;
}

.password-reset-link {
    @include textlink($margin: 30px 0 0);

    display: flex;
    justify-content: center;
    appearance: none;
    border: 0 none;
    background: transparent;
    text-align: center;
    margin: 20px auto 40px;
    cursor: pointer;
}

.account-overview-intro {
    @include body2-medium;

    margin: 0;
}

.login-button-bar {
    margin: 40px 0 30px;
}

.account-overview-navigation {
    list-style-type: none;
    padding: 0;
    margin: 0 0 44px;

    li {
        margin: 0 0 8px;
    }
}
</style>
